import React, { useState, useEffect } from 'react';
import { Container, Row, Card, Button } from 'react-bootstrap';
import { AUTH_URL } from './secret';

function Tracks() {
  const [tracks, setTracks] = useState([]);
  const [token, setToken] = useState("");

  useEffect(() => {
    const hash = window.location.hash
    let token = window.localStorage.getItem("token")

    if (!token && hash) {
        token = hash.substring(1).split("&").find(elem => elem.startsWith("access_token")).split("=")[1]

        window.location.hash = ""
        window.localStorage.setItem("token", token)
    }

    setToken(token)
  }, [])

  useEffect(() => {
    fetch('http://localhost:3001/api/tracks')
      .then(response => response.json())
      .then(data => setTracks(data.tracks));
  }, []);

  async function reloadTracks() {
    fetch('http://localhost:3001/api/tracks')
      .then(response => response.json())
      .then(data => setTracks(data.tracks));
  }

  async function approveMusic(track) {
    console.log("Approuver la musique")
    console.log(track)
    const response = await fetch('http://localhost:3001/api/approve', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(track),
    });

    const data = await response.json();
    console.log(data);
    reloadTracks();
  }

  async function rejectMusic(track) {
    console.log("Refuser la musique")
    console.log(track)
    const response = await fetch('http://localhost:3001/api/reject', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(track),
    });

    const data = await response.json();
    console.log(data);
    reloadTracks();
  }

  async function deleteMusic(track) {
    console.log("Supprimer la musique")
    console.log(track)
    const response = await fetch('http://localhost:3001/api/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(track),
    });

    const data = await response.json();
    console.log(data);
    reloadTracks();
  }

  async function addApprovedTracksToPlaylist() {
    console.log("Ajouter les musiques approuvées à la playlist")
    console.log(token);
    console.log(tracks);
    const approvedTracks = tracks.filter(track => track.status === 'approved').map(track => track.spotify_id);
    const playlistId = '6PFKPHfTkOFB9byACRgWIv';

    const response = await fetch(`https://api.spotify.com/v1/playlists/${playlistId}/tracks`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uris: approvedTracks.map(id => `spotify:track:${id}`)
      }),
    });

    const data = await response.json();
    console.log(data);
    if (data.error) {
      alert('Erreur dans l\'ajout des titres à la playlist');
    } else {
      alert('Musiques ajoutées à la playlist avec succès !');
    }
  } 

  return (
    <Container>
      <Button href="/" className="btn btn-primary mb-3">
        Acceuil
      </Button>
      <Button
        href={AUTH_URL}   
          className="btn btn-success mb-3"
      >
          Connexion à Spotify
      </Button>
      <Button onClick={addApprovedTracksToPlaylist} className="btn btn-primary mb-3">
          Ajouter les musiques approuvées à la playlist
        </Button>
      <Row className="mx-2 row row-cols-4">
        {tracks.map((track, i) => {
          console.log(track);
          return (
            <Card key={i} className="mb-3">
              <Card.Img src={track.image} />
              <Card.Body>
                <Card.Title>{track.name}</Card.Title>
                <Card.Text>
                  {track.artist} | {track.album}
                </Card.Text>
                <Card.Text>
                  ID: {track.id}
                </Card.Text>
                <Card.Text>
                  {track.status}
                </Card.Text>
                <Button href={track.url} target="_blank" className="btn btn-primary">
                  Écouter
                </Button>
                <Button className="btn btn-success" onClick={() => approveMusic(track)}>
                  Approuver
                </Button> 
                <Button className="btn btn-danger" onClick={() => rejectMusic(track)}>
                  Refuser
                </Button>
                <Button className="btn btn-warning" onClick={() => deleteMusic(track)}> 
                  Supprimer
                </Button>
              </Card.Body>
            </Card>
          )
        })}
      </Row>
    </Container>
  );
}

export default Tracks;